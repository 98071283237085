/*12-05-2021 - TO SHOW NG-SELECT OPTIONS TEXT IN WORD WRAP*/
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal!important;
}

/*card*/
.card-body {
    padding: 1rem;
}

/*btn*/
.btn {
    font-size: 12px;
    padding: 0.55rem 0.75rem;
    line-height: 1.5;
    box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
}
.btn-min-width {
    min-width: 96px;
}

/*table*/
.table{
    font-size: 0.9rem;
}
.table th, .table td {
    padding: 10px;
    color:#222;
    vertical-align: middle;
    word-break: break-word;
    min-width: 40px;
}

/*form-control*/
.form-control{
    font-size: 0.91rem;
    padding: .375rem .75rem;
}

/*justify-content-between  - pagination*/
.justify-content-between {
    padding: 15px;
}

/*IMAGE REMOVE BUTTON - CATALOUGES*/
.img-remove{
    align-items: center;
    border-radius: 5px;
    /* display: flex; */
    height: auto;
    justify-content: center;
    margin: 10px;
    max-width: 220px;
    min-height: 140px;
    min-width: 180px;
    padding: 0px 25px;
    position: relative;
}
  
.img-remove-btn{
    opacity: 0;
}

.img-remove:hover .img-remove-btn{
    opacity: 1;
}  

/*SPINNER - LOADER*/
.block-ui-template, .block-ui-spinner{
    font-size: 120%;
}

.icon-spin {
    font-size: 400% !important;
}


/* NETT value - order details */
.bg-lighten-4 .text-right{
    font-size: 16px;
    font-weight: bold;
} 

/* Modal close icon */
.close span {
    font-size: 30px;
    color: red;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link .avatar {
    max-height: 36px;
}



.nav-tabs {
    border-bottom: 0px solid #babfc7;
}

.popover-body {
    background-color: black !important;
    color: white !important;
    font-size: 14px;
}

/*LANDING PAGE - LOGIN POPUP IN CENTER OF PAGE*/
.landing-page .modal-dialog {
    height: 97% !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    padding-bottom: 3%;
  }
  
  .landing-page .modal-content {
    margin: 0 auto;
  }

  
    /*LOADER VIEW FIXED TO PAGE CENTER*/
    .block-ui-spinner, .block-ui-template, .table-loader {
        position: fixed !important;
        /* top: 50% !important; */
        left: 50px;
     }

     /*MAKE SPACE BW LOGO AND FIRST SUBMENU SIDEBAR*/
     .main-menu ul, .vertical-overlay-menu.menu-hide .main-menu ul {
        padding-top: 25px;
        padding-bottom: 50px;
    }

    .main-menu.menu-dark .navigation > li {
        padding: 0;
        line-height: 2.3em;
    }

    .custom-badge1{
            padding:5px;
            font-size:120% !important;
            font-weight:700;
            color:#000 !important;
            min-width:30px;
    }

    /*LOADER CSS - START*/
.loader-primary div
{
    background-color: #666ee8 !important;
}
    /*LOADER CSS - END*/

/* single Date CSS*/
.btn-light {
    background-color: white !important;
    color: #6b6f82 !important;
}
.bg-primary {
    background-color: #666ee8 !important;
    color: #fff !important;
}
/* single Date  CSS- END*/


#insights .slick-prev:before {
    color: #000 !important;
  }
 #insights .slick-next:before {
    color: #000 !important;
  }


.bg-gradient-directional-EC2E3C{
    background-image: linear-gradient(45deg, #d32c37, #EC2E3C);

}
.bg-hexagons1 {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23ff6576' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        box-shadow: lightgreen;


}

     .bg-gradient-directional-danger {
    background-image: linear-gradient(45deg, #ff1635, #ff7c8d);
    }

        /* .bg-gradient-directional-23f95bf47ed{
            background-image: linear-gradient(45deg, #ff1635, #ff7c8d);
        } */
            

.bg-hexagons-shopee{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23f69078' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: lightgreen;

}

.bg-gradient-directional-2395bf47ed{
    background-image: linear-gradient(45deg, #53701bed, #95bf47ed);
}

.bg-hexagons-shopify{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%2399ab47' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: #95bf47ed;

}
    .bg-gradient-directional-2341af4b{
        background-image: linear-gradient(45deg, #335f36, #41af4b);
    }

.bg-hexagons-vend{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23419947' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: #41af4b;

}


.bg-gradient-directional-96588a{
    background-image: linear-gradient(45deg,  #8f2870, #c066a5);
}


.bg-hexagons-woo{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23c066a5' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: #96588a;

}

.bg-gradient-directional-3b519c{
    background-image: linear-gradient(45deg,  #5d79d4, #3b519c);
}


.bg-hexagons-offline{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%233b519c' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: #3b519c;

}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: black !important;
    background-clip: padding-box;
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.md-drppicker {
    position: relative;
    width: auto !important;
    min-width: 550px;
}

/*CAMPAIGNS*/
.cdk-overlay-container {
    position: fixed;
    z-index: 2000;
}
#insights .card{
    margin-bottom: 1px;
}

#insights .card-footer{
    padding: 4px;
}


/*TO SOLVE DROPDOWN HEIGHT HIDDEN AT LAST BLOCK*/
.content .content-wrapper {
    min-height: 900px;
}

.md-drppicker .calendar td, .md-drppicker .calendar th{
    min-width: 28px !important;
}

@media (max-width: 600px){
.md-drppicker.ltr .calendar.right {
    margin-left: 0;
    display: none;
}
    .md-drppicker .ranges ul li {
        font-size: 12px;
        float: left;
    }

.md-drppicker {
    position: relative;
    width: auto !important;
    min-width: 350px;
}
}

.italic {
    font-style: italic;
}

.table-not-bordered th, .table-not-bordered td{
    border: none;
}

.checkboxSpan span{
    vertical-align: top;padding:4px
}
.checkboxInput{
    height: 16px; width: 16px;
}

#customercopy-template{
	padding:4rem;

}

/*SUBMENU -> SUBMENU > ICON*/
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after {
    top: 14px;
}
.main-menu.menu-dark .navigation > li ul .open > ul li > a {
    padding: 12px 18px 12px 34px;
}

/*PRODUCTS*/
.img-fluid-order {
    max-width: 140%;
    height: auto;
}
.logo-img {
    width: 100px;
}

.activeStore {
    color: #4934b2;
    background-color: #f0edfc;
    padding: 5px;
}

.myCustomModalClass .modal-dialog {
    max-width: 1000px !important;
}

.custom-modal-table {
    font-size: 10px !important;
}
form .form-section {
    border-bottom: 1px solid #ccc;
}

.customsearchbar .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    z-index: 1;
    position: relative;
    height: auto;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.65rem;
    max-height: 300px;
    width: auto;
    min-width: 250px;
    max-width: 500px;
    word-break: break-all;
}

.customsearchbar .list-group-item {
    position: relative;
    display: block;
    padding: 0.60rem;
    background-color: #fff;
    border: 0px solid #E4E7ED;
}
.customsearchbar .list-group-item:hover {
background: #f5faff;
cursor:pointer;
}

::-webkit-scrollbar {
    width: 12px;
    height: 10px;
}
 
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,0.3); 
    box-shadow: inset 0 0 7px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background: #fff;
}
 
::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9); 
    box-shadow: inset 0 0 2px rgba(0,0,0,0.9); 
    background: #fff;
}