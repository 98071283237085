// For each breakpoint, define the maximum width of the container in a media query
@mixin mega-menu-container-max-widths($max-widths: $container-max-widths) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint) {
      max-width: $container-max-width;
      $margin-width: calc((100% - #{$container-max-width}) / 2);
      margin: 0 $margin-width;
    }
  }
}
