/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 4 Admin Dashboard Template
	 Version: 3.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/

%extend_hamburger_color {
   .hamburger-inner,
   .hamburger-inner::before,
   .hamburger-inner::after {
      background-color: $navbar-light-bg;
   }
}
@mixin fullNav {
   .navbar-header {
      width: 100% !important;
      padding: 0.5rem 1rem;
      position: relative;

      .menu-toggle {
         top: 2px;
         position: relative;
      }

      .open-navbar-container i {
         font-size: 1.8rem;
      }

      .navbar-brand {
         position: absolute;
         left: 50%;
         top: 0;
         transform: translate(-50%, 0);
      }
   }

   &.navbar-with-menu {
      .navbar-container {
         width: 100%;
         display: table;
         background: #fff;
         padding: 0rem 1rem;
         margin: 0;
         height: auto;
      }
   }
}

.header-navbar {
   padding: 0px;
   min-height: $navbar-height;
   font-family: $font-family-monospace;
   transition: 300ms ease all;
   &.navbar-static-top{
      z-index: 1038;
   }
   .navbar-wrapper {
      width: 100%;
   }

   // add border to navbar
   &.navbar-border {
      border-bottom: 1px solid #e4e7ed;
   }

   &.navbar-dark.navbar-border {
      border-bottom: 1px solid #8596b5;
   }

   // add shadow to navbar
   &.navbar-shadow {
      box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.1);
   }

   // hide top horizontal menu drop-down arrow
   &.navbar-without-dd-arrow {
      .nav-link {
         &.dropdown-toggle::after {
            display: none;
         }
      }
   }

   //center align brand option for horizontal layouts
   &.navbar-brand-center {
      .navbar-header {
         width: 192px;
         position: absolute;
         top: 0;
         left: 50%;
         padding: 0;
         margin: 0;
         transform: translate(-50%, 0);
         z-index: 999;
      }
   }

   .navbar-header {
      height: 100%;
      width: $navbar-header-width;
      height: $navbar-height + 0.4;
      float: left;
      position: relative;
      padding: 0rem 0.85rem;
      transition: 300ms ease all;

      .navbar-brand {
         padding: 18px 0px;
         margin-right: 0;

         .brand-logo {
            width: 36px;
         }

         .brand-text {
            display: inline;
            padding-left: 6px;
            font-weight: 500;
            vertical-align: middle;
         }
      }
   }

   .navbar-container {
      padding: 0rem 18px;
      height: $navbar-height;
      transition: 300ms ease all;
      background: inherit;

      ul.nav {
         li {
            > a.nav-link {
               padding: 1.9rem 1rem;
               font-size: 1.15rem;

               i.flag-icon {
                  margin-right: 0.5rem;
               }
            }

            a.dropdown-user-link {
               padding: 1.2rem 1rem;
               line-height: 23px;

               .user-name {
                  display: inline-block;
                  margin-bottom: 0.4rem;
                  margin-left: 0.2rem;
               }
            }

            a.menu-toggle {
               padding: 1.7rem 1rem 1.6rem 1rem;

               i {
                  font-size: 1.6rem;
               }
            }

            a.nav-link-label {
               padding: 1.7rem 1rem 1.6rem 1rem;
               height: 100%;
            }

            a.dropdown-user-link {
               .avatar {
                  margin-right: 0.5rem;
                  width: 36px;
               }
            }

            a.nav-link-search,
            a.nav-link-expand {
               padding: 1.7rem 1rem 1.7rem 1rem;
            }

            div.input-group {
               padding: 0.7rem 1rem;
            }

            i.ficon {
               font-size: 1.5rem;
            }

            .media-list {
               max-height: 18.2rem;
               top: -20px;
            }

            .scrollable-container {
               position: relative;
            }
         }
      }

      //Navbar notification css
      .dropdown-menu-media {
         width: 24rem;

         .media-list {
            .media {
               padding: 1rem;
               border: none;
               // border-top: 1px solid $border-color;
               border-bottom: 1px solid $border-color;
            }
         }

         .dropdown-menu-header {
            border-bottom: 1px solid $border-color;
         }

         .dropdown-menu-footer {
            position: relative;
            top: -20px;
            a {
               padding: 0.3rem;
               border-top: 1px solid $border-color;
               margin-bottom: -20px;
            }
         }
      }
   }

   &.navbar-without-menu {
      .navbar-container {
         margin-left: 0;
      }
   }

   &.navbar-with-menu {
      .navbar-container {
      }
   }

   .nav-item + .nav-item {
      margin-left: 0rem;
   }

   /* For sm screen*/
   @include media-breakpoint-down(sm) {
      @include fullNav;
   }
}
/* Modern menu For md screen*/
@include media-breakpoint-down(md) {
   [data-menu="vertical-menu-modern"] {
      .header-navbar {
         @include fullNav;
      }
      .navbar-dark,
      .navbar-semi-dark {
         .navbar-header .navbar-nav .nav-link {
            color: $white;
         }
         .navbar-container .navbar-nav .nav-link {
            color: $navbar-dark-bg;
         }
      }
      .navbar-light {
         .navbar-header .navbar-nav .nav-link {
            color: $navbar-dark-bg;
         }
      }
      .navbar-header .navbar-nav {
         .open-navbar-container,
         .nav-menu-main {
            padding: 1rem;
         }
      }
   }
}

.navbar-light {
   background: $navbar-light-bg;

   &.navbar-horizontal {
      background: $navbar-light-bg;
   }

   .navbar-nav {
      li {
         line-height: 1;
      }

      .active {
         &.nav-link {
            background-color: rgba(0, 0, 0, 0.03);
         }
      }

      .disabled {
         &.nav-link {
            color: $nav-link-disabled-color;
         }
      }
   }
}

.navbar-dark {
   background: $navbar-dark-bg;
   .navbar-brand {
      color: $navbar-light-bg !important;
      .brand-text{
         color: $navbar-light-bg !important;
      }
   }
   &.navbar-horizontal {
      background: $navbar-dark-bg;
   }

   @extend %extend_hamburger_color;

   .nav-search {
      .form-control,
      .btn-secondary {
         color: $navbar-light-bg;
         background: $navbar-dark-bg;
      }
   }

   //    .navbar-brand .brand-text {
   //       color: $white !important;
   //    }

   .navbar-nav {
      li {
         line-height: 1;
      }

      .active {
         &.nav-link {
            background-color: rgba(255, 255, 255, 0.05);
         }
      }

      .disabled {
         &.nav-link {
            color: $gray-200;
         }
      }
   }
}

.navbar-semi-dark {
   .navbar-header {
      background: $navbar-dark-bg;

      .brand-text {
         color: $white;
      }
   }

   background: $navbar-light-bg;

   .navbar-nav {
      li {
         line-height: 1;
      }

      .nav-link {
         color: $gray-600;
      }

      .active {
         &.nav-link {
            background-color: rgba(0, 0, 0, 0.03);
         }
      }

      .disabled {
         &.nav-link {
            color: $nav-link-disabled-color;
         }
      }
   }
}

.navbar-semi-light {
   .navbar-header {
      background: $navbar-light-bg;

      .brand-text {
         color: $gray-800;
      }
   }

   background: $gray-600;

   .navbar-nav {
      li {
         line-height: 1;
      }

      .nav-link {
         color: $navbar-light-bg;
      }

      .active {
         &.nav-link {
            background-color: rgba(0, 0, 0, 0.03);
         }
      }

      .disabled {
         &.nav-link {
            color: $nav-link-disabled-color;
         }
      }
   }
}

// Mega menu
// ------------------------------
.mega-dropdown {
   position: static !important;

   .dropdown-menu {
      display: flex;
   }
}

.navigation-main,
.navbar-nav {
   .mega-dropdown-menu {
      font-family: $font-family-sans-serif;
      width: calc(100% - 50px);
      padding: 0.5rem 0;
      // width: 98%;
      /* fallback if needed */
      margin: 0px 25px;
      box-shadow: none;
      box-shadow: 0px 5px 75px 2px rgba(64, 70, 74, 0.2);
      z-index: 9999;

      ul {
         padding: 0;
         margin: 0;

         li {
            list-style: none;

            a {
               display: block;
               color: $gray-800;
               &.dropdown-item{
                  &:active{
                     color: $white;
                  }
               }

               .children-in {
                  float: right;
               }

               &:hover {
                  text-decoration: none;
                  color: $gray-600;
               }

               &:focus {
                  text-decoration: none;
                  color: $gray-600;
               }
            }
         }

         a.dropdown-item {
            padding: 10px;
         }
      }

      p.accordion-text {
         line-height: $line-height-base;
      }

      .dropdown-menu-header {
         padding: 0.5rem 0;
         border-bottom: 1px solid $border-color;
      }

      h6 i.la {
         font-size: 1.1rem;
      }
   }
}

// TODO: Need to generalize it
.horizontal-top-icon-menu {
   .mega-dropdown-menu {
      margin: 0px 25px !important;
   }
}

// Mega menu max width on boxed layout only
.container {
   .mega-dropdown-menu {
      @include mega-menu-container-max-widths();
   }
}

@-moz-document url-prefix() {
   ul {
      li {
         a {
            .children-in {
               position: relative;
               right: 3px;
               top: -14px;
            }
         }
      }
   }
}

// Mega menu list
// ------------------------------
.menu-list {
   list-style: none;
   padding: 0;
   overflow: hidden;
}

.menu-panel {
   padding: 0;
}

.sliding-menu {
   overflow: hidden;
   position: relative;

   ul {
      float: left;
      margin: 0;
      padding: 0;

      li {
         list-style: none;
         margin: 0;
      }
   }
}

// Mega "Hamburger" Icon Animations
// ------------------------------
.navbar-menu-icon {
   width: 24px;
   margin: 16px 2px !important;
   position: relative;
   margin: 0px auto;
   transform: rotate(0deg);
   transition: 0.5s ease-in-out;
   cursor: pointer;
}

.navbar-menu-icon {
   span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $gray-600;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
   }
}

.navbar-menu-icon span:nth-child(1) {
   top: 0px;
}

.navbar-menu-icon span:nth-child(2) {
   top: 10px;
}

.navbar-menu-icon span:nth-child(3) {
   top: 20px;
}

.navbar-menu-icon.show span:nth-child(1) {
   top: 10px;
   transform: rotate(135deg);
}

.navbar-menu-icon.show span:nth-child(2) {
   opacity: 0;
   left: -60px;
}

.navbar-menu-icon.show span:nth-child(3) {
   top: 10px;
   transform: rotate(-135deg);
}

// Media queries for device support
// =========================================
@include media-breakpoint-down(sm) {
   .header-navbar {
      // search bar
      #navbar-mobile {
         .search-input.open {
            .input {
               width: 132px;
               &::placeholder {
                  color: $input-placeholder-color;
               }
            }
         }
      }

      // generic navbar dropdown specific
      .navbar-nav {
         .show {
            position: static;
         }

         .open-navbar-container {
            padding-top: 0.9rem;
         }
      }

      .navbar-container {
         .show {
            .dropdown-menu {
               right: 0;
               left: 0 !important;
               float: none;
               width: auto;
               margin-top: 0;
               max-height: 400px;
               overflow-x: hidden;
               overflow-y: hidden; //prev. was scroll
            }
         }

         ul.nav {
            li {
               &.nav-item i {
                  margin-right: 0.2rem;
               }

               > a.nav-link {
                  padding: 1.5rem 0.4rem;

                  i.flag-icon {
                     margin-right: 0.2rem;
                  }
               }

               a.dropdown-user-link {
                  padding: 0.8rem 0.6rem !important;
               }

               a.nav-link-label {
                  padding: 1.4rem 0.4rem;
                  i.ft-bell {
                     position: relative;
                     left: 8px;
                  }
               }

               a.nav-link-search,
               a.nav-link-expand {
                  padding: 1.4rem 1rem 1rem 1rem;
               }

               .dropdown-toggle::after {
                  margin-right: 0;
                  margin-left: -2px;
               }
            }
         }
      }
   }

   // dark navbar
   .navbar-dark,
   .navbar-semi-dark {
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
         background-color: $navbar-light-bg;
      }

      .navbar-header {
         .navbar-nav {
            .nav-link {
               color: $navbar-light-bg;
            }
         }
      }

      .navbar-container {
         .navbar-nav {
            .nav-link {
               color: $gray-600;
            }
         }
      }
   }

   // light navbar
   .navbar-light,
   .navbar-semi-light {
      .navbar-header {
         .navbar-nav {
            .nav-link {
               color: $navbar-dark-bg;
            }
         }
      }

      .navbar-container {
         .navbar-nav {
            .nav-link {
               color: $gray-600;
            }
         }
      }
   }
}

.headroom {
   will-change: transform;
   transition: transform 200ms linear;
}

.headroom--pinned-top {
   transform: translateY(0%);
}

.headroom--unpinned-top {
   transform: translateY(-100%);
}

.headroom--pinned-bottom {
   transform: translateY(0%);
}

.headroom--unpinned-bottom {
   transform: translateY(100%);
}

// iPad support for navbr
@include media-breakpoint-down(lg) {
   .header-navbar {
      .navbar-container {
         ul.nav {
            li {
               > a.nav-link {
                  // padding: 1.4rem 0.4rem 0.8rem 0.4rem;
                  i.flag-icon {
                     margin-right: 0.2rem;
                  }
               }

               a.dropdown-user-link {
                  // padding: 0.8rem 0.6rem;
                  padding: 1.2rem 0.6rem;
               }

               a.nav-link-label {
                  // padding: 1.2rem 0rem 1rem 0.8rem;
               }

               a.nav-link-search,
               a.nav-link-expand {
                  // padding: 1.5rem 1rem 1rem 1rem;
               }

               .dropdown-toggle::after {
                  margin-right: 0;
                  margin-left: -2px;
               }

               .selected-language {
                  display: none;
               }

               a.dropdown-user-link {
                  .user-name {
                     display: none;
                  }
               }
            }
         }
      }
   }
}

@mixin extend_mobile_specific($menu_height) {
   .header-navbar {
      .navbar-container {
         .show {
            .dropdown-menu {
               max-height: $menu_height;
            }
         }
      }
   }
}

@mixin responsive_sub_nav {
   //For responsive sub nav
   #navbar-mobile.navbar-collapse {
      .navbar-nav {
         margin: 0;
         flex-flow: row wrap;

         .dropdown-menu {
            position: absolute;
         }

         .nav-item {
            float: left;
         }
      }
   }
}

@media (max-width: 767px) {
   @include responsive_sub_nav;
}
@include media-breakpoint-down(md) {
   @include responsive_sub_nav;
}

/* ----------- iPhone 5, 5S  iPhone 6----------- */

/* Landscape */

@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
   @include extend_mobile_specific(180px);
}

/* ----------- iPhone 6+ ----------- */

/* Landscape */

@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
   @include extend_mobile_specific(280px);
}

// For Navbars Of Navbar Section

@media screen and (max-width: 1024px) {
   #navbar-demo {
      .header-navbar {
         .navbar-header {
            width: auto;

            .menu-toggle.ft-menu {
               position: relative;
               // top: 0.6rem !important;
            }

            .nav-link.open-navbar-container {
               .la.la-ellipsis-v {
                  position: relative;
                  // top: -2.5rem;
               }
            }
         }
      }

      .navbar-container {
         margin-left: auto;

         .navbar-btn {
            .nav-link.btn {
               padding: 0.5rem 1rem;
            }
         }
      }
   }

   .internationalization {
      .card {
         .card-body {
            .header-navbar {
               .navbar-wrapper {
                  .navbar-header {
                     width: auto;

                     .menu-toggle.ft-menu {
                        position: relative;
                        //  top: 0.6rem !important; // (1) commented (1),(2),(3) for internationalization navbar
                     }

                     .nav-link.open-navbar-container {
                        .la.la-ellipsis-v {
                           position: relative;
                           top: 0.5rem;
                           // top: -2.5rem; // (2)
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

@media screen and (max-width: 768px) {
   #navbar-demo {
      .header-navbar.navbar-semi-dark,
      .header-navbar.navbar-brand-center {
         .navbar-header {
            width: 60px;
         }
      }

      .navbar-container {
         .nav-form-inline {
            .input-group {
               .form-control {
                  width: 95px;
               }
            }
         }
      }
   }
}

@media screen and (max-width: 540px) {
   #navbar-demo {
      .navbar-container {
         #navbar-mobile5.navbar-badges {
            .badge-up {
               position: absolute;
               top: 1px;
               right: 16rem;
            }
         }

         .add-on-right {
            margin-top: 5rem !important;
            margin-bottom: 1rem;
         }

         .nav-form-select {
            margin-bottom: 1rem;
            float: left !important;
         }

         .nav-radio,
         .nav-checkbox {
            label {
               color: $black !important;
            }
         }

         .nav-checkbox {
            float: left !important;
         }
      }
   }

   .internationalization {
      .card {
         .card-body {
            .header-navbar {
               .navbar-wrapper {
                  .navbar-header {
                     width: 100%;

                     .menu-toggle.ft-menu {
                        position: relative;
                        top: 0.6rem !important;
                     }

                     .nav-link.open-navbar-container {
                        .la.la-ellipsis-v {
                           position: relative;
                           // top: -2.5rem; // (3)
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

body.vertical-layout {
   &.menu-open {
      .main-menu {
         .navigation {
            .navigation-header {
               .la-ellipsis-h,
               .nav-menu-icon {
                  display: none;
               }
            }
         }
      }
   }
}
