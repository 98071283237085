/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css";

body{
    background-color: #f7f7f7 !important;
}

.margin-top-70{
    margin-top: 70px;
}*/
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:host ::ng-deep .email-fixed-header {
  min-height: calc(100% - 207px) !important;
  margin-top: 0 !important;
  overflow-y: hidden !important;
}


ngb-typeahead-window {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pointer {
  cursor: pointer !important;
}
.close-icon {
  font-size: 24px;
  color: #fb483a;
}
.save-loader {
  position: absolute;
  top: 80%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}
.table-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-image: url(/assets/img/lod.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff7d;
}
.save-loader-opacity {
  pointer-events: none;
}
.dot {
  width: 20px;
  height: 20px;
  background: #3ac;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #32aacc;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #64aacc;
}

.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #96aacc;
}

.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #c8aacc;
}

.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #faaacc;
}

@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-o-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.table tbody + tbody {
  border-top: 2px solid #cccfd3 !important;
}
.ng-clear-wrapper {
  display: block;
}
.modal-header {
  border-bottom: none !important;
  padding: 10px;
}
.modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  /* box-shadow: 0 5px 20px rgb(0 0 0 / 31%) !important; */
  border: none;
  padding: 10px;
  background-color: #fff;
}
.modal-content .modal-body {
  color: #777;
  padding: 15px 25px;
}
.modal-footer {
  border-top: none !important;
  background-color: transparent;
}
.modal-footer {
  border-radius: 0 0 2px 2px;
  background-color: #fafafa;
  padding: 4px 6px;
  height: 56px;
  width: 100%;
  text-align: right;
}
.modal-title {
  font-size: 16px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
